
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'snzj',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public next () {
    const url = getCurrentQuery('url')
    let { source = '11', ...params } = this.$route.query
    const json = encodeURIComponent(JSON.stringify(params))
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setMBankAuth()
    if (url) {
      location.replace(`${location.origin}/partner?redirect=${url}&source=11&data=${json}`)
    } else {
      location.replace(`${location.origin}/partner?redirect=https%3A%2F%2Fsnzj-h5.benlai.com%2F&source=11&data=${json}`)
    }
  }

  public created () {
    const url = getCurrentQuery('url')
    let { source = '11', ...params } = this.$route.query
    const json = encodeURIComponent(JSON.stringify(params))
    if (getMBankAuth()) {
      if (url) {
        location.replace(`${location.origin}/partner?redirect=${url}&source=11&data=${json}`)
      } else {
        location.replace(`${location.origin}/partner?redirect=https%3A%2F%2Fsnzj-h5.benlai.com%2F&source=11&data=${json}`)
      }
    } else {
      this.showLogin = true
    }
  }
}
